import "./EndScreen.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function EndScreen(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dot: true,
  };

  const goodEndCard = (
    <div className="endScreenCardText">
      Смотри, сколько пассажиров нашли работу мечты. Может, настало время
      подумать о&nbsp;своей? <br />
      <br /> На&nbsp;сайте Альфа-Банка много вакансий для начинающих специалистов.
      Выбирай&nbsp;ту, что по&nbsp;душе, и&nbsp;залетай на&nbsp;собеседование.
    </div>
  );

  const badEndCard = (
    <div className="endScreenCardText">
      Плохая новость: ты&nbsp;узнал, что работать карьерным лифтёром&nbsp;&mdash; не&nbsp;твоё.
      Хорошая: среди вакансий Альфа-Банка найдётся&nbsp;та, которая подойдёт именно
      тебе.
      <br />
      <br /> Изучай наш список работ мечты, выбирай&nbsp;ту, что по&nbsp;душе, и&nbsp;залетай
      на&nbsp;собеседование.
    </div>
  );

  return (
    <>
      <div
        className={`${props.endResult ? "endScreenPositive" : "endScreenNegative"
          } scale`}
      >
        <a
          href="https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpu&pr=RANDOM&puid1="
          className="endScreenLogo"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
        <div
          className={`${props.endResult ? "endScreenCardPositive" : "endScreenCardNegative"
            }`}
        >
          {props.endResult ? goodEndCard : badEndCard}
          <div className="endScreenSliderContainer">
            <Slider {...settings}>
              <div className="endScreenSliderButtonContainer">
                <button
                  onClick={() => {
                    window.open(
                      "https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpw&pr=RANDOM&puid1=",
                      "_blank",
                      "noreferrer,noopener"
                    );
                    window.goal('btn_fin_card')
                  }
                  }
                  className="endScreenSliderButton"
                >
                  Доставка карт
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.open(
                      "https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpy&pr=RANDOM&puid1=",
                      "_blank",
                      "noreferrer,noopener"
                    );
                    window.goal('btn_fin_call')
                  }
                  }
                  className="endScreenSliderButton"
                >
                  Колл-центр
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.open(
                      "https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcqa&pr=RANDOM&puid1=",
                      "_blank",
                      "noreferrer,noopener"
                    );
                    window.goal('btn_fin_sales')
                  }
                  }
                  className="endScreenSliderButton"
                >
                  Продажи
                </button>
              </div>
            </Slider>
          </div>
        </div>
        <a
          href="https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=bavxfb&pr=RANDOM&puid1="
          className="endScreenButton buttonRed"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => window.goal('btn_product')}
        >
          Участвовать
        </a>
      </div>
    </>
  );
}

export default EndScreen;
