import "./FloorBar.css";

function FloorBar(props) {
  return (
    <div className="floor">
      <div className="floorBar">
        <div className="floorNumberBox"></div>
        <div className="floorNameBox"></div>
        <div className="floorLogoBox"></div>
      </div>
      <div
        className={`floorBarData ${props.isFloorChanging ? "floorBarTransition" : ""
          }`}
      >
        <div className="floorNumber">{props.nextFloor?.floorNumber || ""}</div>
        <div className="floorName">{props.nextFloor?.floorName || ""}</div>
        <a
          href="https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpu&pr=RANDOM&puid1="
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="floorLogo">
            <img src="assets/alfaLogo.svg"></img>
          </div>
        </a>
      </div>
      <div
        className={`floorBarData ${props.isFloorChanging ? "floorBarTransition" : ""
          } ${props.initialTransition ? "initialFloorBarTransition" : ""}`}
      >
        <div className="floorNumber">
          {props.currentFloor?.floorNumber || ""}
        </div>
        <div className="floorName">{props.currentFloor?.floorName || ""}</div>
        <a
          href="https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpu&pr=RANDOM&puid1="
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="floorLogo">
            <img src="assets/alfaLogo.svg"></img>
          </div>
        </a>
      </div>
    </div>
  );
}

export default FloorBar;
