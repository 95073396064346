import "./ElevatorScreen.css";
import { useState } from "react";
import ButtonRed from "./ButtonRed";

function ElevatorScreen(props) {
  const characterListUnsorted = [...props.characterList];
  const zindexSortedCharacterList = characterListUnsorted.sort(
    (a, b) => a.zindexOrder - b.zindexOrder
  );
  const [hoverCharacter, setHoverCharacter] = useState(false);

  return (
    <div className="elevatorContainer">
      <div className="elevatorScreen">
        <div
          className={`elevatorLeftDoor ${props.isFloorChanging ? "elevatorDoorTransition" : ""
            } ${props.initialTransition ? "elevatorDoorTransition" : ""}`}
        ></div>
        <div
          className={`elevatorRightDoor ${props.isFloorChanging ? "elevatorDoorTransition" : ""
            } ${props.initialTransition ? "elevatorDoorTransition" : ""}`}
        ></div>
        {props.characterList.map((char) => (
          <img
            className={`${char.name} ${hoverCharacter === char.id ? "elevatorScreenHover" : ""
              } ${props.mobileClickedChar === char.id ? "elevatorScreenHover" : ""
              }`}
            src={`/assets/characters/${char.name}.png`}
            key={char.id}
            id={char.id}
            alt={char.name}
          ></img>
        ))}
        {
          <>
            <img
              src="/assets/characters/mapImg.png"
              className="mapImg"
              useMap="#charMap"
            ></img>
            <map name="charMap">
              {zindexSortedCharacterList.map((char) => (
                <area
                  onMouseEnter={() => setHoverCharacter(char.id)}
                  onMouseLeave={() => setHoverCharacter(false)}
                  onClick={props.onCharacterClick}
                  className="area"
                  coords={char.areaCoords}
                  shape="poly"
                  id={char.id}
                  key={char.id}
                ></area>
              ))}
            </map>
          </>
        }
      </div>
      {props.isPopupClosed && (
        <div className="elevatorControls">
          <button onClick={props.onExit} className="elevatorExit"></button>
          <ButtonRed
            isDisabled={props.isFloorChanging}
            onClick={props.onOpenCharacterSlider}
            className="elevatorEnter"
            text="Кого высадить?"
          />
        </div>
      )}
    </div>
  );
}

export default ElevatorScreen;
