import "./ComixSlider.css";
import { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ButtonRed from "../ButtonRed";
import PopupComix from "../Overlay/PopupComix";

function ComixSlider(props) {
  const [currentComix, setCurrentComix] = useState(0);
  const [showSticker, setShowSticker] = useState(false);

  const comixSliderRef = useRef();
  const comixSliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: document.documentElement.clientWidth >= 1200 ? true : false,
    afterChange: (current) => {
      setCurrentComix(current);
    },
  };

  function nextComixHandler() {
    setCurrentComix((prev) => ++prev);
    currentComix !== 3
      ? comixSliderRef.current.slickNext()
      : setShowSticker(true);
  }

  const comixData = {
    shark: [
      { id: 1, src: "/assets/comix/sharkComix1.png" },
      { id: 2, src: "/assets/comix/sharkComix2.png" },
      { id: 3, src: "/assets/comix/sharkComix3.png" },
      { id: 4, src: "/assets/comix/sharkComix4.png" },
    ],
    owl: [
      { id: 1, src: "/assets/comix/owlComix1.png" },
      { id: 2, src: "/assets/comix/owlComix2.png" },
      { id: 3, src: "/assets/comix/owlComix3.png" },
      { id: 4, src: "/assets/comix/owlComix4.png" },
    ],
    aloe: [
      { id: 1, src: "/assets/comix/aloeComix1.png" },
      { id: 2, src: "/assets/comix/aloeComix2.png" },
      { id: 3, src: "/assets/comix/aloeComix3.png" },
      { id: 4, src: "/assets/comix/aloeComix4.png" },
    ],
  };

  return (
    <div className="comixSliderContainer">
      <div className="comixSliderCardContainer">
        <div className="comixSliderCardLeftDoor"></div>
        <div className="comixSliderCardRightDoor"></div>
        <Slider ref={comixSliderRef} {...comixSliderSettings}>
          {comixData[props.comixType].map((slide) => (
            <img
              key={slide.id}
              className="comixSliderCard"
              src={slide.src}
              alt="Comix"
            ></img>
          ))}
        </Slider>
      </div>
      <button onClick={props.onExit} className="sliderExit"></button>
      <ButtonRed
        onClick={nextComixHandler}
        className="comixSliderCardButton"
        text="Далее"
      />
      {showSticker && (
        <PopupComix
          comixType={props.comixType}
          onClick={() => {
            window.goal(`comics_close_${props.comixType}`)
            setShowSticker(false);
            props.onCloseComix();
          }}
        />
      )}
    </div>
  );
}

export default ComixSlider;
