import "./PopupComix.css";
import { createPortal } from "react-dom";

function PopupComix(props) {
  const links = {
    shark: 'https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcqa&pr=RANDOM&puid1=',
    owl: 'https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpw&pr=RANDOM&puid1=',
    aloe: 'https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpy&pr=RANDOM&puid1=',
  }

  return createPortal(
    <>
      <div className="comixStickerBackground" onClick={props.onClick}>
        <div className="comixStickerContainer scale">
          <div className={`comixSticker comixStickerImg-${props.comixType}`}>
            <button
              className="comixStickerClose"
              onClick={props.onClick}
            ></button>
          </div>
          <a
            href={links[props.comixType]}
            className="comixStickerApply buttonRed"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => window.goal(`btn_prod_${props.comixType}`)}
          >
            Откликнуться на вакансию
          </a>
        </div>
      </div>
    </>,
    document.getElementById("root")
  );
}

export default PopupComix;
