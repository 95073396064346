import "./PopupTip.css";
import { useState } from "react";
import { createPortal } from "react-dom";
import ButtonRed from "../ButtonRed";

function PopupTip(props) {
  const [showPopup, setShowPopup] = useState(1);

  function popupHandler(e) {
    setShowPopup(e);
    tutor_metrika(e);
  }

  function tutor_metrika(e) {
    window.goal(`tutor_screen_${e - 1}`)
  }

  const firstPopup = (
    <>
      <div className="popup ">

        <div className="popupContainer scale">
          <img
            className="popupImg1"
            src={"/assets/popupImg1.png"}
            alt="Instruction1"
          ></img>
          <img
            onClick={() => props.onClosePopup()}
            className="popupSkip"
            src={"/assets/popupSkip.png"}
            alt="Skip"
          ></img>
          <div className="popupElevator">
            {props.characterList.map((char) => (
              <img
                className={`${char.name}`}
                src={`/assets/characters/${char.name}.png`}
                key={char.id}
                alt={char.name}
              ></img>
            ))}
          </div>
          <ButtonRed
            onClick={() => popupHandler(2)}
            className="popupButton popupButtonTransition"
            text="Ок, понятно"
          />
        </div>
      </div>
    </>
  );

  const secondPopup = (
    <>
      <div className="popup ">
        <div className="popupContainer scale">
          <img
            className="popupImg2"
            src={"/assets/popupImg2.png"}
            alt="Instruction2"
          ></img>
          <img
            onClick={() => props.onClosePopup()}
            className="popupSkip"
            src={"/assets/popupSkip.png"}
            alt="Skip"
          ></img>
          <ButtonRed
            onClick={() => popupHandler(3)}
            className="popupButton"
            text="Дальше"
          />
        </div>
      </div>
    </>
  );

  const thirdPopup = (
    <>
      <div className="popup ">
        <div className="popupContainer scale">
          <img
            className="popupImg3"
            src={"/assets/popupImg3.png"}
            alt="Instruction3"
          ></img>
          <img
            onClick={() => props.onClosePopup()}
            className="popupSkip"
            src={"/assets/popupSkip.png"}
            alt="Skip"
          ></img>
          <ButtonRed
            onClick={() => popupHandler(4)}
            className="popupButton"
            text="Хорошо"
          />
        </div>
      </div>
    </>
  );
  const fourthPopup = (
    <>
      <div className="popup ">
        <div className="popupContainer scale">
          <img
            className="popupImg4"
            src={"/assets/popupImg4.png"}
            alt="Instruction4"
          ></img>
          <img
            onClick={() => props.onClosePopup()}
            className="popupSkip"
            src={"/assets/popupSkip.png"}
            alt="Skip"
          ></img>
          <ButtonRed
            onClick={() => { props.onClosePopup(true); tutor_metrika(5) }}
            className="popupButton"
            text="Поехали"
          />
        </div>
      </div>
    </>
  );

  return createPortal(
    <>
      {showPopup === 1 && firstPopup}
      {showPopup === 2 && secondPopup}
      {showPopup === 3 && thirdPopup}
      {showPopup === 4 && fourthPopup}
    </>,
    document.getElementById("root")
  );
}

export default PopupTip;
