import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CharacterSlider.css";
import ButtonRed from "../ButtonRed";
import ComixSlider from "./ComixSlider";

function CharacterSlider(props) {
  const charactersListUnsorted = [...props.characterList];
  const characterList = charactersListUnsorted.sort((a, b) => a.id - b.id);
  const currentFloor =
    props.characterList[props.characterList.length - 1].floorNumber;
  const clickedCharIndex = characterList.findIndex(
    (elem) => Number(elem.id) === Number(props.clickedCharId)
  );
  const charSliderRef = useRef();

  const [answer, setAnswer] = useState({
    status: false,
    result: null,
    answerText: null,
  });
  const [currentCharacterIndex, setCurrentCharacterIndex] = useState(
    props.clickedCharId ? clickedCharIndex : 0
  );
  const [showComix, setShowComix] = useState({ status: false, type: null });

  function hideAnswerHandler() {
    const currentResult = answer.result;

    if (currentFloor === 2) {
      setShowComix({ status: true, type: "shark" });
      return;
    }
    if (currentFloor === 4) {
      setShowComix({ status: true, type: "owl" });
      return;
    }
    if (currentFloor === 7) {
      setShowComix({ status: true, type: "aloe" });
      return;
    }

    setAnswer((prev) => ({ ...prev, status: false }));
    props.characterList.length === 1 && props.onExit(props.score);
    props.onRemoveCharacter();
  }

  function closeComixHandler() {
    setAnswer((prev) => ({ ...prev, status: false }));
    props.characterList.length === 1 && props.onExit(props.score);
    props.onRemoveCharacter();
  }

  function showAnswerHandler() {
    const currentCharacter = characterList[currentCharacterIndex];
    const currentResult = currentCharacter.floorNumber === currentFloor;
    const currentAnswerText = currentResult
      ? currentCharacter.answerText.right
      : currentCharacter.answerText.wrong;
    setAnswer(() => ({
      status: true,
      result: currentResult,
      answerText: currentAnswerText,
    }));
    props.setNewScore(currentResult ? 1 : 0);
  }

  const charSliderSettings = {
    className: "center",
    centerMode: true,
    infinite: props.characterList.length === 1 ? false : true,
    centerPadding: "23px",
    slidesToShow: 1,
    initialSlide: props.clickedCharId ? clickedCharIndex : 0,
    speed: 250,
    arrows: document.documentElement.clientWidth >= 1280 ? true : false,
    dots: !answer.status,
    draggable: !answer.status,
    swipe: !answer.status,
    afterChange: (current) => {
      setCurrentCharacterIndex(current);
    },
  };

  return (
    <>
      <div className="sliderContainer">
        <button
          onClick={() => props.onCloseSlider()}
          className="hiddenCloseButton"
        ></button>
        <Slider ref={charSliderRef} {...charSliderSettings}>
          {characterList.map((char) => (
            <div className="sliderCardContainer" key={char.id}>
              {!answer.status && <button className="closeButton"></button>}
              <img
                className="sliderCard"
                src={`/assets/characters/${char.name}Card.png`}
              ></img>
              <button
                className="hiddenButton"
                onClick={!answer.status ? showAnswerHandler : hideAnswerHandler}
                id={char.floorNumber}
              ></button>
            </div>
          ))}
        </Slider>
        {answer.status && (
          <div className="sliderAnswer">
            <div className="sliderAnswerResult">
              {answer.result ? "✅ Правильно" : "❌ Неправильно"}
            </div>
            <div className="sliderAnswerText">{answer.answerText}</div>
          </div>
        )}
        <button onClick={() => props.onExit(null, true)} className="sliderExit"></button>
        <ButtonRed
          className="sliderEnter"
          text={answer.status ? "Далее" : "Высадить"}
        />
      </div>
      {showComix.status && (
        <ComixSlider
          onExit={props.onExit}
          comixType={showComix.type}
          onCloseComix={closeComixHandler}
        />
      )}
    </>
  );
}

export default CharacterSlider;
