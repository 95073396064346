import "./StartScreen.css";
import ButtonRed from "./ButtonRed";

function StartScreen(props) {
  return (
    <div className="startScreen">
      <a
        href="https://ads.adfox.ru/265714/goLink?p1=cbbzy&p2=frfe&p5=sqcpu&pr=RANDOM&puid1="
        className="startScreenLogo"
        target="_blank"
        rel="noopener noreferrer"
      ></a>
      <ButtonRed
        onClick={props.onCloseStartScreen}
        className="startScreenButton"
        text="Вызвать лифт"
      ></ButtonRed>
    </div>
  );
}

export default StartScreen;
