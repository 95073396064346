import "./ButtonRed.css";

function ButtonRed(props) {
  return (
    <button
      disabled={props.isDisabled}
      onClick={props.onClick}
      className={`buttonRed ${props.className}`}
    >
      {props.text}
    </button>
  );
}

export default ButtonRed;
